import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/chapin-walker-day-jr.jpg";
  const fullName = `Chapin Walker Day, Jr.`;
  const zoomUrl =
    "https://us02web.zoom.us/j/84718757137?pwd=dUpkUU50cmRadEJ2TFNoM1ZSUjB6Zz09";
  const zoomPhoneNumber = "(301) 715-8592";
  const zoomMeetingId = "847 1875 7137";
  const zoomMeetingPasscode = "048543";
  const birthDate = new Date(1936, 4 - 1, 6);
  const deathDate = new Date(2020, 12 - 1, 6);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 2 - 1, 20);
  const memorialTime = "11 AM PST / 2 PM EST";
  const inviteText = `Please join us in remembering our father, Chapin Walker Day, Jr.  We are holding a virtual memorial service to honor his life and we hope that you can attend.  The service will be held on February 20, 2021 at 11 AM PST/2 PM EST. We welcome anyone who would like to help us celebrate his memory.`;
  const inviteChild = <></>;
  const obituary = `Chapin Walker Day, Jr. passed away peacefully at home at the age of 84 after a long bout with Parkinson's. He was a devoted father, grandfather and husband. A 1958 graduate of the US Naval Academy, he was also an avid computer scientist, backpacker, music lover and photographer. He is survived by his wife of 56 years, Sandra Elder Day, his 4 children (Rosemarie, Chapin, Roby and David), their spouses (Steve, Elena, Dawn and Anne) and 8 grandchildren (Kate, Andrew, Ellie, Jasper, Sophia, Jordan, Jackie and Nathan). He was very loved and is truly missed.
  \nA burial service was held at Miramar National Cemetery in late December.`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Chapin%20recording%20-%20updated.mp4",
      title: "Memorial Recording",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <p>
        Please consider a donation in his name to the{" "}
        <a
          href="//www.teamsierra.org/memorial/chapinwalkerdayjr"
          target="_blank"
          rel="noreferrer"
        >
          Sierra Club
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
